import { Component } from '@angular/core';
import { AuthService } from '../../auth.service';
import { ProfessorService } from '../../services/professor.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EmailValidator } from '../../validators/email';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  email;
  senha;
  mensagemErro;
  closeResult: string;
  emailValido = false;
  showLoading = false;

  constructor(protected authService: AuthService, protected professorService: ProfessorService, private router: Router, public modalService: NgbModal) {
    this.email = undefined;
    this.senha = undefined;
  }

  login() {
    this.showLoading = true;
    this.mensagemErro = undefined;
    this.authService.loginPorEmail(this.email.trim(), this.senha)
      .then(authData => {
        this.professorService.buscaPorEmail(this.email).valueChanges().subscribe(obj => {
          this.authService.setProfessor(obj[0]);
          this.showLoading = false;
          this.router.navigate(['/dashboard']);
        });
      }, error => {
        this.mensagemErro = "Login ou senha incorroretos, tente novamente.";
        this.showLoading = false;
      });
      
  }


  esqueceuSenha(content) {

    this.validarEmail();

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  enviarEmailEsqueceuSenha() {
    if (this.emailValido) {
      this.authService.enviaRecuperacaoSenha(this.email);
      alert("Email de reset de senha foi enviado. Verifique seu email.");
    } else {
      alert("Por favor, informe um email válido.");
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  validarEmail() {
    this.emailValido = EmailValidator.isValid(this.email);
  }
}
