import { Http, Headers } from '@angular/http';
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class PagSeguroService {

    urlcheckout = "https://pagseguro.uol.com.br/v2/checkout/payment.html?code=";
    urlcheckoutsandobox = "https://sandbox.pagseguro.uol.com.br/v2/checkout/payment.html?code=";


    urlpreaprovals = "https://ws.pagseguro.uol.com.br/pre-approvals/";
    urlpreaprovalssandobox = "https://ws.sandbox.pagseguro.uol.com.br/pre-approvals/";
    urlSession = "https://www.insumoagricola.com.br/pagseguro/api/session.php";    
    urlTokenCard = "https://www.insumoagricola.com.br/pagseguro/api/tokencard.php";
    urlAssinatura = "https://www.insumoagricola.com.br/pagseguro/api/assinatura.php";

    constructor(public http: Http) {

    }

    geraSessionPagamentoPagseguro(data){

        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        this.http.post('https://impactit.com.br/pagseguro/examples/assinatura/assinatura-simples_1.php', data, { headers: headers }).subscribe(obj => {
            
            console.log(obj);
            /*
            let respostapagseguro = obj.json();
            let endpoint = data.producao ? this.urlcheckout : this.urlcheckoutsandobox;
            if (respostapagseguro.code !== undefined) {
                window.location.href = endpoint + respostapagseguro.code;
                
            } else {
                alert("Ocorreu um erro no processamento do pedido, tente mais tarde novamente.");
                
            }*/
        });        

    }

    geraSessionRecorrenciaPagseguro(data){
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        this.http.post('https://www.insumoagricola.com.br/pagseguro/api/api2.php', data, { headers: headers }).subscribe(obj => {
            
            console.log(obj);
            let respostapagseguro = obj.json();
            let endpoint = data.producao ? this.urlcheckout : this.urlcheckoutsandobox;
            if (respostapagseguro.code !== undefined) {
                window.location.href = endpoint + respostapagseguro.code;
                
            } else {
                alert("Ocorreu um erro no processamento do pedido, tente mais tarde novamente.");
                
            }
        }); 
    }


    async geraSession():Promise<any>{
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');       
        //headers.append('Access-Control-Allow-Origin','*');
        return await this.http.post(this.urlSession, {headers:headers}).toPromise();            
    }
    
    async getBrand(idsession, cardbin):Promise<any>{                  
        return await this.http.get("https://df.uol.com.br/df-fe/mvc/creditcard/v1/getBin?tk="+idsession+'&creditCard='+cardbin).toPromise();            
    }

    async getTokenCard(cartao):Promise<any>{        
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');    
        //headers.append('Access-Control-Allow-Origin','*');    
        return await this.http.post(this.urlTokenCard,{'sessionid':cartao.sessionid, 'amount':cartao.amount, 'card':cartao.card, 'brand':cartao.brand, 'cvv':cartao.cvv, 'month':cartao.month, 'year':cartao.year}, {headers:headers}).toPromise();            
    }

    async geraAssinatura(dados):Promise<any>{        
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');   
        //headers.append('Access-Control-Allow-Origin','*');     
        return await this.http.post(this.urlAssinatura, dados, {headers:headers}).toPromise();            
    }
}
