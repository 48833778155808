import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class TemaService {

  private path: string = 'tema';

  constructor(public firebaseService: FirebaseService) {
  }

  list() {
    return this.firebaseService.getList(this.path);
  }

  listActives() {
    return this.firebaseService.query(this.path, ref => ref.where('desativado', '==', false ).orderBy('criadoEm'));
  }

  listOpenInDate() {
    let datamais = new Date();
    datamais.setDate(datamais.getDate()+8);

    return this.firebaseService.query(this.path, ref => ref.where('fim', '>=', new Date().getTime() ));
  }

  listOrderCreate() {
    return this.firebaseService.query(this.path, ref => ref.orderBy('criadoEm', "desc"));
  }

  listOrderCreateUser(idusuario) {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', idusuario ).orderBy('criadoEm', "desc"));
  }

  listOrderCreateLimit() {
    return this.firebaseService.query(this.path, ref => ref.orderBy('criadoEm', "desc").limit(54));
  }

  listInicioLimit(limit) {
    return this.firebaseService.query(this.path, ref => ref.orderBy('inicio', "desc").limit(limit));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  deleteFieldArquivo(id) {
    this.get(id).ref.update({
      arquivo: firebase.firestore.FieldValue.delete()
    });
  }

  
}
