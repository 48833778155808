import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AtividadeService {

  private path: string = 'atividade'; 

  constructor(public firebaseService: FirebaseService, public authService: AuthService) {
  }

  list() {
    return this.firebaseService.getList(this.path);
  }

  listaAtividadeByUserId() {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', this.authService.idusuario));
  }

  listaAtividadeByUserIdQuePodeCorrigir() {
    return this.firebaseService.query(this.path, ref => ref.where('idprofessorcorrigir', '==', this.authService.idusuario).orderBy("dataEnvio", "desc"));
  }

  listaAtividadeSemCorretor() {
    return this.firebaseService.query(this.path, ref => ref.where('idprofessorcorrigir', '==', null));
  }

  listaAtividadeSemCorrecao() {
    return this.firebaseService.query(this.path, ref => ref.where('idcorrecao', '==', null).where('idprofessorcorrigir', '==', this.authService.idusuario).orderBy("dataEnvio", "desc"));
  }
  
  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }
  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }
  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }
  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  deleteFieldArquivo(id) {
    this.get(id).ref.update({
      arquivo: firebase.firestore.FieldValue.delete()
    });
  }

  listaAtividadeByUserIdAOrderDate() {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', this.authService.idusuario).orderBy('dataEnvio', "asc"));
  }

  listaAtividadeByUserIdAOrderDateFilter(datainicio) {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', this.authService.idusuario).where("dataEnvio",">=",datainicio).orderBy('dataEnvio', "asc"));
  }

  listaAtividadeByUserIdAOrderDateDesc(usuario) {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', usuario).orderBy('dataEnvio', "desc"));
  }

}
