import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PlanoService {

  private path: string = 'plano';

  constructor(public firebaseService: FirebaseService) {
  }

  list() {
    return this.firebaseService.getList(this.path);
  }

  listOrderByMeses() {
    return this.firebaseService.query(this.path, ref => ref.orderBy("meses"));
  }

  getByField(campo, valor){
    return this.firebaseService.query(this.path, ref => ref.where(campo, '==', valor));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }
}
