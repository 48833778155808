import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class FirebaseGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let pro = new Promise((res) => {
      this.authService.user.subscribe(obj => res(obj))
    }).then(ob => { return ob ? true : false });

    if (!pro) {
      this.router.navigate(['/capa']);
    }
  
    return pro;
  }
}
