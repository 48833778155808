import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { CapaComponent } from './views/capa/capa.component';
import { FirebaseGuard } from './firebase.guard';
import { FirebaseGuardAluno } from './firebase.guard.aluno';
import { FirebaseGuardProfessor } from './firebase.guard.professor';
import { FirebaseGuardVendedor } from './firebase.guard.vendedor';

export const routes: Routes = [

  {
    path: '',
    component: CapaComponent,
    data: {
      title: 'Fabrica de redação online'
    }
  },  
  {
    path: 'capa',
    component: CapaComponent,
    data: {
      title: 'Fabrica de redação online'
    }
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    data: {},
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        canActivate: [FirebaseGuard]
      },
      {
        path: 'atividade',
        loadChildren: './views/atividade/atividade.module#AtividadeModule',
        canActivate: [FirebaseGuardAluno]
      },
      {
        path: 'atividade/novo',
        loadChildren: './views/atividade/atividade.module#AtividadeModule',
        canActivate: [FirebaseGuardAluno]
      },
      {
        path: 'atividade/:id',
        loadChildren: './views/atividade/atividade.module#AtividadeModule',
        canActivate: [FirebaseGuardAluno]
      },
      {
        path: 'correcao',
        loadChildren: './views/correcao/correcao.module#CorrecaoModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'tema',
        loadChildren: './views/tema/tema.module#TemaModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'lista-atividades-aluno',
        loadChildren: './views/lista-atividades-aluno/lista-atividades-aluno.module#ListaAtividadesAlunoModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'professor',
        loadChildren: './views/professor/professor.module#ProfessorModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'material',
        loadChildren: './views/material/material.module#MaterialModule',
        canActivate: [FirebaseGuard]
      },
      {
        path: 'liberarcorrecao',
        loadChildren: './views/liberarcorrecao/liberarcorrecao.module#LiberarCorrecaoModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'enviar-atividade',
        loadChildren: './views/enviar-atividade/enviar-atividade.module#EnviarAtividadeModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'plano',
        loadChildren: './views/plano/plano.module#PlanoModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'cupom',
        loadChildren: './views/cupom/cupom.module#CupomModule',
        canActivate: [FirebaseGuardProfessor]
      }
      ,
      {
        path: 'visualizacorrecao/:id',
        loadChildren: './views/visualizacorrecao/visualizacorrecao.module#VisualizaCorrecaoModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'grafico',
        loadChildren: './views/grafico/grafico.module#GraficoModule',
        canActivate: [FirebaseGuardAluno]
      },
      {
        path: 'compraplanos',
        loadChildren: './views/compraplanos/compraplanos.module#CompraPlanosModule',
        canActivate: [FirebaseGuardAluno]
      },
      {
        path: 'relatoriocorrecao',
        loadChildren: './views/relatoriocorrecao/relatoriocorrecao.module#RelatorioCorrecaoModule',
        canActivate: [FirebaseGuardProfessor]
      },      
      {
        path: 'meusdados',
        loadChildren: './views/meusdados/meusdados.module#MeusDadosModule',
        canActivate: [FirebaseGuardAluno]
      },
      {
        path: 'vendedor',
        loadChildren: './views/vendedor/vendedor.module#VendedorModule',
        canActivate: [FirebaseGuardProfessor]
      },
      {
        path: 'relatorio-gerencial-correcao',
        loadChildren: './views/relatorio-gerencial-correcao/relatorio-gerencial-correcao.module#RelatorioGerencialCorrecaoModule',
        canActivate: [FirebaseGuardProfessor]
      }
    ]
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
/*
  constructor(r: Router) {
    this.logaRotas('/', r.config);    
  }

  logaRotas(pai: String, rotas: Routes) {
    for (let i = 0; i < rotas.length; i++) {            
      if (rotas[i].children) {
        rotas[i].canActivate = [FirebaseGuard];        
        this.logaRotas(rotas[i].path + '/', rotas[i].children);
      }
    }
  }*/

}
