import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { ProfessorService } from '../../services/professor.service';

import { AlunoService } from '../../services/aluno.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  usuario = {};
  chave = "";
  public menualuno = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },{
      title: true,
      name: 'Aluno',
      class: "",
    },
    {
      name: 'Lista de Atividades',
      url: '/atividade',
      icon: 'far fa-file',
      class: ""
    },
    {
      name: 'Materiais de Apoio',
      url: '/material',
      icon: 'fas fa-info',
      class: ""
    },
    {
      name: 'Evolução',
      url: '/grafico',
      icon: 'fa fa-line-chart',
      class: ""
    },
    {
      name: 'Meus Dados',
      url: '/meusdados',
      icon: 'fa fa-cogs',
      class: ""
    },
    {
      name: 'Assinar',
      url: '/compraplanos',
      icon: 'fa fa-pencil-square-o',
      class: ""
    }];
  public menuprofessor = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: true,
      name: 'Professor',
      class: "",
    },
    {
      name: 'Lista de Correções',
      url: '/correcao',
      icon: 'far fa-check-circle',
      class: ""
    },
    {
      name: 'Relatório de correções',
      url: '/relatoriocorrecao',
      icon: 'fa fa-line-chart',
      class: ""
    }
   ];

  public menuprofessortema = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: true,
      name: 'Professor',
      class: "",
    },
    {
      name: 'Lista de Correções',
      url: '/correcao',
      icon: 'far fa-check-circle',
      class: ""
    },
    {
      name: 'Relatório de correções',
      url: '/relatoriocorrecao',
      icon: 'fa fa-line-chart',
      class: ""
    },
    {
      name: 'Cadastros',
      url: '',
      icon: 'icon-cursor',
      class: "",
      children: [
        {
          name: 'Temas',
          url: '/tema',
          icon: 'fas fa-chalkboard',          
        },
  
        {
          name: 'Materiais de Apoio',
          url: '/material',
          icon: 'fas fa-info'
        },
        
      ]
    }
  ]

  public menuvendedor = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    }
  ]
  public menuProfessorAdministrador = [
    {
      title: true,
      name: 'Administrativo',
      class: ""
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Lista de Correções',
      url: '/correcao',
      icon: 'far fa-check-circle',
      class: ""
    },        
    {
      name: 'Cadastros',
      url: '',
      icon: 'icon-cursor',
      class: "",
      children: [
        {
          name: 'Temas',
          url: '/tema',
          icon: 'fas fa-chalkboard',          
        },
        {
          name: 'Professores',
          url: '/professor',
          icon: 'fas fa-chalkboard-teacher',
        },
        {
          name: 'Materiais de Apoio',
          url: '/material',
          icon: 'fas fa-info'
        },
        {
          name: 'Liberar Correção',
          url: '/liberarcorrecao',
          icon: 'fas fa-user-edit'
        },
        {
          name: 'Enviar atividade',
          url: '/enviar-atividade',
          icon: 'far fa-file',
        },
        {
          name: 'Atividades de alunos',
          url: '/lista-atividades-aluno',
          icon: 'far fa-file',
        },
        {
          name: 'Planos',
          url: '/plano',
          icon: 'fas fa-hand-holding-usd'
        },
        {
          name: 'Cupom',
          url: '/cupom',
          icon: 'fa fa-bookmark'
        },
        {
          name: 'Vendedor',
          url: '/vendedor',
          icon: 'fa fa-users'
        },
        {
          name: 'Relatório',
          url: '/relatorio-gerencial-correcao',
          icon: 'fa fa-line-chart'
        }
      ]
    }
  ];
  isProfessor = false;
  isPago = false;

  constructor(protected authService: AuthService, protected professorService: ProfessorService, public alunoService: AlunoService, public route: Router) {
    /*
    let temp = this.professorService.buscaPorEmailLogado(this.authService.email).valueChanges().subscribe(obj => {
      this.authService.setProfessor(obj[0]);
      this.isProfessor = obj[0] === undefined ? false : true;
      this.usuario = obj[0];

      if (this.usuario === undefined) {
        this.alunoService.buscaPorUid(this.authService.idusuario).valueChanges().subscribe(res => {
          this.usuario = res[0];
          this.isPago = this.usuario["vigencia"] == 0;
        });
      }

      this.navItems = this.gerarMenu();
      temp.unsubscribe();
    });*/
 
    
    this.carregamentoInicial();


    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized')
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  logout() {
    this.authService.logout();
  }

  async carregamentoInicial() {
        
    this.isProfessor = this.authService.isProfessor();
    
    if (this.authService.isProfessor()) {
      this.usuario = this.authService.professor;      
      
    } else if (this.authService.isAluno()) {
      this.usuario = this.authService.aluno;
      //this.isPago = this.usuario["vigencia"] == 0;     
      
    } else if (this.authService.isVendedor()) {
      this.usuario = this.authService.vendedor;   
      
    }
   
  }

  abreCompra() {
    this.route.navigate(['/compraplanos']);
  }

}
