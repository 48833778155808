import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { PlanoService } from '../../services/plano.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlunoService } from '../../services/aluno.service';
import { PagSeguroService } from '../../services/pagseguro.service';
import { Router } from '@angular/router';
import { EmailValidator } from '../../validators/email';
import { Http } from '@angular/http';
import { TemaService } from '../../services/tema.service';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-capa',
  templateUrl: './capa.component.html',
  styleUrls: ['./capa.component.css']
})
export class CapaComponent implements OnInit {

  items: Observable<any[]>;
  temas: any;
  closeResult: string;
  email;
  senha;
  mensagemErro;
  emailValido = false;
  showLoading = false;
  modalOpened: NgbModalRef;
  modalResetSenha: NgbModalRef;
  itemComprar;
  emailValidoCompra = false;
  tema1 = "";
  tema2 = "";
  data1 = 0;
  data2 = 0;
  arquivo1 = null;
  arquivo2 = null;
  tituloselecionado = "";
  pdfselecionado = null;

  constructor(public authService: AuthService,
    public planoService: PlanoService,
    public temaService: TemaService,
    public router: Router,
    public http: Http,
    public pagSeguroService: PagSeguroService,
    public alunoService: AlunoService,
    public modalService: NgbModal,
    private sanitizer: DomSanitizer) {
    this.email = "";
    this.senha = "";
  }
  // vinicius.perenha@impactit.com.br
  async login() {
    this.showLoading = true;
    this.mensagemErro = undefined;
    this.authService.professor = undefined;
    this.authService.aluno = undefined;
    this.authService.vendedor = undefined;
    this.authService.loginPorEmail(this.email.trim(), this.senha)
      .then(async authData => {
        await this.authService.buscaProfessor(this.email);
        await this.authService.buscaAluno(this.email);
        await this.authService.buscaVendedor(this.email);
        this.modalOpened.close();
        this.showLoading = false;
        this.router.navigate(['/dashboard']);
      }, error => {
        this.mensagemErro = 'Login ou senha incorretos, tente novamente.';
        this.showLoading = false;
      });

  }


  ngOnInit() {
    this.items = this.planoService.getByField("mostrarplano", true).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as any };
      })));

    this.temaService.listInicioLimit(2).valueChanges().subscribe(obj => {
      this.tema1 = obj[0]["titulo"];
      this.data1 = obj[0]["inicio"];
      this.arquivo1 = this.sanitizer.bypassSecurityTrustResourceUrl(obj[0]["arquivo"]);

      this.tema2 = obj[1]["titulo"];
      this.data2 = obj[1]["inicio"];
      this.arquivo2 = this.sanitizer.bypassSecurityTrustResourceUrl(obj[1]["arquivo"]);

    });


  }

  public moveTo(target): void {
    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }


  menuMobile() {
    if (document.getElementById('menu').style.display == 'inline') {
      document.getElementById('menu').style.display = 'none';
    } else {
      document.getElementById('menu').style.display = 'inline';
    }
  }


  // modal code
  openModal(content) {
    this.mensagemErro = undefined;
    this.modalOpened = this.modalService.open(content, { size: 'lg', centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  esqueceuSenha(content) {
    this.validarEmail();
    this.modalResetSenha = this.modalService.open(content);
  }


  enviarEmailEsqueceuSenha() {
    if (this.emailValido) {
      this.authService.enviaRecuperacaoSenha(this.email);
      this.modalResetSenha.close();
      alert('Email de reset de senha foi enviado. Verifique seu email.');
    } else {
      alert('Por favor, informe um email válido.');
    }

  }

  validarEmail() {
    this.emailValido = EmailValidator.isValid(this.email);
  }

  validarEmailCompra(compraEmail) {
    this.emailValidoCompra = EmailValidator.isValid(compraEmail);
  }


  openModalCompra(content, item) {
    this.mensagemErro = undefined;
    this.itemComprar = item;
    this.modalOpened = this.modalService.open(content, { size: 'lg', centered: true });
  }


  validarCompra(compraNome, compraDDD, compraTelefone, compraSenha) {
    return !this.emailValidoCompra ||
      compraNome === undefined || compraNome === '' ||
      compraDDD === undefined || compraDDD === '' ||
      compraTelefone === undefined || compraTelefone === '' ||
      compraSenha === undefined || compraSenha === '';
  }

  comprar(compraEmail, compraNome, compraDDD, compraTelefone, compraSenha) {

    this.showLoading = true;
    this.mensagemErro = undefined;

    let aluno = {
      'nome': compraNome,
      'email': compraEmail,
      'liberado': true,
      'ddd': compraDDD,
      'telefone': compraTelefone,
      'senha': compraSenha,
      'vigencia': 0,
      'criadoEm': new Date().getTime(),
      'uid': '',
      'excluido': false
    }

    this.gravaAlunoCompra(aluno);
    /*
    let headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    
    this.http.post('http://impactit.com.br/sites/pagseguroapi/api2.php', data, { headers: headers }).subscribe(obj => {
    let respostapagseguro = obj.json();  
    
      this.showLoading = false;
      
      if(respostapagseguro.code !== undefined){
        console.log(respostapagseguro.code);
        window.location.href = 'https://pagseguro.uol.com.br/v2/checkout/payment.html?code='+respostapagseguro.code;  
      }
     // window.location.href = 'https://pagseguro.uol.com.br/v2/checkout/payment.html?code='+obj['code'];
    });  
    */

  }

  gravaAlunoCompra(aluno) {
    this.alunoService.buscaPorEmail(aluno.email).valueChanges().subscribe(obj => {
      if (obj[0] === undefined) {

        this.showLoading = false;
        this.modalOpened.close();

        this.authService.criarUsuario(aluno.email, aluno.senha).then(obj => {
          if (obj.code != "auth/email-already-in-use") {
            aluno["uid"] = obj.user.uid;
            aluno.UltimaLiberacaoEm = new Date().getTime();
            this.alunoService.add(aluno);
            alert('Conta gratuita criada com sucesso! Faça login com seu e-mail e senha.');
          } else {
            alert("Email já em uso, por favor, informe outro email.");
          }
        });

      } else {
        this.showLoading = false;
        this.mensagemErro = 'E-mail já cadastrado. Favor entrar em contato.';
      }
    });

  }
}
