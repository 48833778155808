import { Http, Headers } from '@angular/http';
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class EmailsService {

    
    urlCupom = "https://us-central1-fabrica-redacao.cloudfunctions.net/sendMailCupomFabrica";
    urlNotificaCorrecao = "https://us-central1-fabrica-redacao.cloudfunctions.net/sendMailNotificaCorrecaoLiberada";
    urlSemanaGratis = "https://us-central1-fabrica-redacao.cloudfunctions.net/sendMailNotificaWeekFree";
    urlNotificacaoCompraNaoFinalizada = "https://us-central1-fabrica-redacao.cloudfunctions.net/sendMailNotificacaoCompraPendente";
    urlNotificacaoCorrecaoAberto = "https://us-central1-fabrica-redacao.cloudfunctions.net/sendMailNotificaCorrecaoProfessorLiberada";
    
    constructor(public http: Http) {

    }

    async enviaCupomDesconto(cupom, aluno):Promise<any>{
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');        
        return await this.http.post(this.urlCupom, {"cupom":cupom, "dest":aluno.email, "nome":aluno.nome},{headers:headers}).toPromise();            
    }

    async enviaEmailAvisoCorrecao(atividade, aluno):Promise<any>{
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');        
        return await this.http.post(this.urlNotificaCorrecao, {"atividade":atividade, "dest":aluno.email, "nome":aluno.nome},{headers:headers}).toPromise();            
    }

    async enviaEmailSemanaGratis(uid, aluno):Promise<any>{
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');        
        return await this.http.post(this.urlSemanaGratis, {"uid":uid, "dest":aluno.email, "nome":aluno.nome},{headers:headers}).toPromise();            
    }

    async enviaNotificacaoCompraNaoFinalizada(uid, aluno):Promise<any>{
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');        
        return await this.http.post(this.urlNotificacaoCompraNaoFinalizada, {"uid":uid, "dest":aluno.email, "nome":aluno.nome},{headers:headers}).toPromise();            
    }

    async enviaEmailAvisoCorrecaoEmAberto(atividade, aluno, professor):Promise<any>{
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');        
        return await this.http.post(this.urlNotificacaoCorrecaoAberto, {"atividade":atividade, "dest":professor.login, "nome":professor.nome, "aluno":aluno},{headers:headers}).toPromise();            
    }
}
