import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoCompraService {

  private path: string = 'solicitacao-compra';

  constructor(public firebaseService: FirebaseService, public authService: AuthService) {
  }

  list() {
    return this.firebaseService.getList(this.path);
  }

  listOrderCreate() {
    return this.firebaseService.query(this.path, ref => ref.orderBy('criadoEm', "desc"));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  listFilter(filter, result) {
    return this.firebaseService.query(this.path, ref => ref.where(filter, '==', result ));
  }

  listFilterOperator(filter, result, operator) {
    return this.firebaseService.query(this.path, ref => ref.where(filter, operator, result ));
  }

  listDoubleFilter(filter1, result1, filter2, result2 ) {
    return this.firebaseService.query(this.path, ref => ref.where(filter1, '==', result1 ).where(filter2, '==', result2 ));
  }

  listDoubleFilterOperator(filter1, result1, operator1, filter2, result2, operator2 ) {
    return this.firebaseService.query(this.path, ref => ref.where(filter1, operator1, result1 ).where(filter2, operator2, result2 ));
  }
  
}
