import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AtividadeComponent } from './atividade.component';
import { AtividadeRoutingModule } from './atividade-routing.module';
import { CommonModule } from '@angular/common';
import { DetalhesComponent } from './detalhes/detalhes.component';
import { DetalhesCorrecaoComponent } from './detalhescorrecao/detalhes.component';

@NgModule({
  imports: [
    NgbModule,
    FormsModule,
    AtividadeRoutingModule,  
    BsDropdownModule,
    ButtonsModule.forRoot(),
    CommonModule
  ], 
  declarations: [ AtividadeComponent, DetalhesComponent, DetalhesCorrecaoComponent ]
})
export class AtividadeModule { }
