import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { ProfessorService } from './services/professor.service';
import { AlunoService } from './services/aluno.service';
import { VendedorService } from './services/vendedor.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  email: string = "";
  idusuario: string = "";
  logado = false;
  professor = undefined;
  aluno = undefined;
  vendedor = undefined;
  isPago = false;

  public user: Observable<firebase.User>;

  constructor(public afAuth: AngularFireAuth, private professorService: ProfessorService,
    private alunoService: AlunoService, private vendedorService: VendedorService) {

    this.user = afAuth.authState;

    this.user.subscribe(u => {
      if (u) {
        this.logado = true;
        this.email = u.email;
        this.idusuario = u.uid;
        this.buscaProfessor(this.email);
        this.buscaAluno(this.email);
        this.buscaVendedor(this.email);
      }

    });


  }

  async buscaProfessor(email): Promise<any> {
    let temp = await this.professorService.buscaPorEmailLogado(email.toLowerCase()).valueChanges().subscribe(obj => {
      this.professor = obj[0];
      temp.unsubscribe();
    });
  }
  async buscaAluno(email): Promise<any> {
    
    let sub = await this.alunoService.buscaPorEmail(email.toLowerCase()).valueChanges().subscribe(alu => {
      this.aluno = alu[0];
      //console.log(this.aluno);
      if (this.aluno !== undefined) {
        this.isPago = this.aluno["vigencia"] == 0;
      }
      
    });
  }
  async buscaVendedor(email): Promise<any> {
    let vend = await this.vendedorService.buscaPorEmail(email.toLowerCase()).valueChanges().subscribe(v => {
      this.vendedor = v[0];
      vend.unsubscribe();
    });
  }


  criarUsuario(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(newEmail.toLowerCase(), newPassword)
      .then(response => response)
      .catch(erro => erro);
  }

  enviaRecuperacaoSenha(email) {
    this.afAuth.auth.sendPasswordResetEmail(email.toLowerCase());
  }

  deletarUsuario(email) {

  }

  loginPorEmail(email: string, senha: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, senha);
  }

  setProfessor(professor) {
    this.professor = professor;
  }

  login() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(() => this.logado = true);
  }

  logout() {
    this.isPago = false;
    this.logado = false;
    this.professor = undefined;
    this.aluno = undefined;
    this.vendedor = undefined;
    this.idusuario = "";
    this.email = "";
    this.afAuth.auth.signOut();
  }

  isProfessor() {
    return this.professor !== undefined && this.aluno === undefined && this.vendedor === undefined;
  }

  isProfessorAdminstrador() {
    return this.professor !== undefined && this.professor["admin"] && this.aluno === undefined && this.vendedor === undefined;
  }

  isProfessorTema() {
    return this.professor !== undefined && this.professor["tema"] && this.aluno === undefined && this.vendedor === undefined;
  }

  isAluno() {
    return this.professor === undefined && this.aluno !== undefined && this.vendedor === undefined;
  }

  isVendedor() {
    return this.professor === undefined && this.aluno === undefined && this.vendedor !== undefined;
  }

}
