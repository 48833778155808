import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})

export class ComentariosCorrecaoService {

  private path: string = 'comentarios-correcao';

  constructor(public firebaseService: FirebaseService,
    public authService: AuthService) {
  }

  list(correcao) {
    return this.firebaseService.query(this.path, ref => ref.where('idcorrecao', '==', correcao));
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  getByField(campo, valor){
    return this.firebaseService.query(this.path, ref => ref.where(campo, '==', valor));
  }

  getComentariosProfessorNaoLidos(idusuario){
    return this.firebaseService.query(this.path, ref => ref.where('idusuariocorrecao', '==', idusuario));
  }

  getComentariosAlunoNaoLidos(idusuario){
    return this.firebaseService.query(this.path, ref => ref.where('idaluno', '==', idusuario));
  }
}
