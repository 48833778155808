import { NgModule } from '@angular/core';
import { Routes,
     RouterModule } from '@angular/router';

import { AtividadeComponent } from './atividade.component';
import { DetalhesComponent } from './detalhes/detalhes.component';
import { DetalhesCorrecaoComponent } from './detalhescorrecao/detalhes.component';

const routes: Routes = [
  {
    path: '',
    component: AtividadeComponent,
    data: {
      title: 'Atividade'
    }
  },
  {
    path: 'novo',
    component: DetalhesComponent,
    data: {
      title: 'Atividade'
    }    
  },
  {
    path: ':id',
    component: DetalhesComponent,
    data: {
      title: 'Atividade'
    }    
  },
  {
    path: 'correcao/:id',
    component: DetalhesCorrecaoComponent,
    data: {
      title: 'Atividade'
    }    
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtividadeRoutingModule {}
