import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireStorage } from 'angularfire2/storage';
import { AuthService } from '../../../auth.service';
import { TemaService } from '../../../services/tema.service';
import { AtividadeService } from '../../../services/atividade.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileService } from '../../../services/mobile.service';
import { AlunoService } from '../../../services/aluno.service';


@Component({
  templateUrl: 'detalhes.component.html'
})
export class DetalhesComponent implements OnInit {

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  email = "";
  chave: string;
  selecionado = {};
  file = null;
  logo = {};
  idusuario = "";
  temas = [];
  mensagemErro;
  pdf = null;
  mostrarLink = false;
  aluno:any = {};
  habilitaBotao = true;
  datahoje = new Date().getTime();
  liberarCorrecaoUsuario = true;
  temasPendentesNovo: Observable<any[]>;
  mostraloading = false;
  pdftema=null;

  constructor(protected route: ActivatedRoute,
    private service: AtividadeService,
    private router: Router,
    private storage: AngularFireStorage,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private temaService: TemaService,
    private alunoService: AlunoService) {
    this.file = null;
    if (MobileService.verificarSeEhMobile()) {
      this.mostrarLink = true;
    }
    

  }

  ngOnInit() {
    if (this.authService.logado) {
      this.email = this.authService.email;
      this.idusuario = this.authService.idusuario;
      this.alunoService.buscaPorUid(this.idusuario).valueChanges().subscribe(res => this.aluno = res[0])
    }
    this.chave = this.route.params["value"]["id"];
    this.init();
  }

  private init() {
    this.selecionado = {};
    this.selecionado["tema"] = {};
    this.file = null;
    this.logo = {};
    this.habilitaBotao = true;
    this.liberarCorrecaoUsuario=true;        


    if (this.chave !== undefined) {
      this.temaService.get(this.chave).valueChanges().subscribe(obj => {
        this.selecionado["tema"] = obj;
        this.liberarCorrecaoUsuario=true;        
        if((this.selecionado["tema"]["arquivo"]!==undefined)||(this.selecionado["tema"]["arquivo"]!=="")){
          this.pdftema = this.sanitizer.bypassSecurityTrustResourceUrl(this.selecionado["tema"]["arquivo"]);
        }
        if ( this.selecionado["tema"]["atividade_aluno"] !== undefined) {
          
          for (let obj of  this.selecionado["tema"]["atividade_aluno"]) {
            
            if (obj["idusuario"] === this.idusuario) {
              this.liberarCorrecaoUsuario=false;
            }
          }
          
        }
        console.log(this.liberarCorrecaoUsuario,"---------------")
        //verifica se já foi enviada a atividade do tema, se nao, verifica se há saldo
        if(this.liberarCorrecaoUsuario){
          this.geraTemasPendentesNovo();
        }

      })
      
    }
  }
  geraTemasPendentesNovo() {
    this.temaService.get(this.chave).valueChanges().subscribe(a=>{

      //console.log(data.length);

      //data.forEach(a=>{
        let achou = false;
        let achouuem = false;
        let bloquearCorrecao = false;
                  
        if (a["atividade_aluno"] !== undefined) {
          achou = false;
          for (let obj of a["atividade_aluno"]) {
            if (obj["idusuario"] === this.idusuario) {
              achou = true;
            }
          }
        }
  
        if (a["atividade_aluno_uem"] !== undefined) {
          achouuem = false;
          for (let obj of a["atividade_aluno_uem"]) {
            if (obj["idusuario"] === this.idusuario) {
              achouuem = true;
            }
          }
        }
  
  
        if ((this.aluno.plano.quantidadeCorrecoes == undefined) || (this.aluno.plano.quantidadeCorrecoes == 4)) {
          if ((achou == true) || (achouuem == true)) {
            bloquearCorrecao = true;
          }
        } else  if (this.aluno.plano.quantidadeCorrecoes == 8) {
          if (achou == true && achouuem == true) {
            bloquearCorrecao = true;
          }
        }
        console.log(achou, achouuem, bloquearCorrecao, this.aluno.plano.quantidadeCorrecoes, "passou aqui")
        if(this.liberarCorrecaoUsuario){
          this.liberarCorrecaoUsuario = !bloquearCorrecao;
        }
      })
      
    //})

  }


  uploadFile(event) {
    this.mostraloading=true;
    if (!event.target.files[0].type.includes("image") && !event.target.files[0].type.includes("pdf")) {
      this.mensagemErro = "Tipo de arquivo inválido, por favor insira uma imagem ou um PDF!";
      return;
    }

    if (event.target.files[0].type.includes("image")) {
      this.inserirImagem(event);
    } else {
      this.inserirPdf(event);
    }
  }

  inserirImagem(event) {
    this.file = event.target.files[0];
    this.selecionado['lastModified'] = new Date().getTime();
    this.selecionado['tipoarquivo'] = "img";

    let reader = new FileReader();

    reader.onload = (e: any) => {
      this.logo = e.target.result;
      this.mostraloading = false;
    }

    reader.readAsDataURL(event.target.files[0]);

    this.logo = reader.result;
  }

  inserirPdf(event) {

    this.file = event.target.files[0];
    this.selecionado['lastModified'] = new Date().getTime();
    this.selecionado['tipoarquivo'] = "pdf";

    const filePath = `/atividades/ativi-${this.authService.email}-${this.selecionado["lastModified"]}.pdf`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, this.file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.toPromise().then(du => {
          this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(du);
          this.mostraloading = false;

        });
      })
    ).subscribe();


  }

  removerArquivoTemporario() {
    this.logo = null;
    this.file = null;
  }

  removerArquivoFirebase() {
    this.storage.storage.refFromURL(this.pdf.changingThisBreaksApplicationSecurity).delete();

    this.logo = null;
    this.file = null;
    this.pdf = null;
  }

  salvar() {
    this.mostraloading = true;
    this.habilitaBotao = false;
    if (this.selecionado['tipoarquivo'] == "img") {
      this.salvarImagem();
    } else {
      this.salvarPdf();
    }
  }

  salvarImagem() {

    const filePath = `/atividades/ativi-${this.authService.email}-${this.selecionado["lastModified"]}` + '.' + this.selecionado['tipoarquivo'];
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, this.file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.toPromise().then(du => {
          this.service.add({ arquivo: du, idtema: this.chave, tema: this.selecionado['tema'], usuario: this.email, dataEnvio: this.selecionado['lastModified'], idusuario: this.idusuario, tipoarquivo: this.selecionado['tipoarquivo'], "idcorrecao": null, "idprofessorcorrigir": null })
            .then(obj => {
              if ((this.selecionado["tema"]["tipoTema"] == undefined) || (this.selecionado["tema"]["tipoTema"] == 'ENEM')) {
                if (this.selecionado["tema"]["atividade_aluno"] == undefined) {
                  this.selecionado['tema']["atividade_aluno"] = [];
                }
                this.selecionado["tema"]["atividade_aluno"].push({ idatividade: obj.id, idusuario: this.idusuario });
              }

              if ((this.selecionado["tema"]["tipoTema"] == 'UEM')) {
                if (this.selecionado["tema"]["atividade_aluno_uem"] == undefined) {
                  this.selecionado['tema']["atividade_aluno_uem"] = [];
                }
                this.selecionado["tema"]["atividade_aluno_uem"].push({ idatividade: obj.id, idusuario: this.idusuario });
              }

              this.temaService.update(this.chave, this.selecionado["tema"]);
            });
            this.mostraloading = false;
          this.router.navigate(['/atividade']);

          alert("Objeto salvo");

        });
      })
    ).subscribe();
  }

  salvarPdf() {
    this.service.add({ arquivo: this.pdf['changingThisBreaksApplicationSecurity'], idtema: this.chave, tema: this.selecionado['tema'], usuario: this.email, dataEnvio: this.selecionado['lastModified'], idusuario: this.idusuario, tipoarquivo: this.selecionado['tipoarquivo'], "idcorrecao": null, "idprofessorcorrigir": null })
      .then(obj => {

        if (this.selecionado["tema"]["atividade_aluno"] == undefined) {
          this.selecionado['tema']["atividade_aluno"] = [];
        }
        this.selecionado["tema"]["atividade_aluno"].push({ idatividade: obj.id, idusuario: this.idusuario });

        this.temaService.update(this.chave, this.selecionado["tema"]);
      });
      this.mostraloading = false;
    this.router.navigate(['/atividade']);

    alert("Objeto salvo");
  }

}
