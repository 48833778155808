import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { TemaService } from '../../../services/tema.service';
import { CorrecaoService } from '../../../services/correcao.service';
import { AlunoService } from '../../../services/aluno.service';
import { ComentariosCorrecaoService } from '../../../services/comentarios-correcao.service';
import { Chart } from 'chart.js';

@Component({
  templateUrl: 'detalhes.component.html'
})
export class DetalhesCorrecaoComponent implements OnInit {

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  email = "";
  chave: string;
  selecionado = {};
  file = null;
  logo = {};
  idusuario = "";
  temas = [];
  somanotas = 0;
  comentarionovo = "";
  aluno = {};
  comentarios: Observable<any[]>;
  idcorrecao = "";
  usuariocorrecao = "";
  listacomentarios = {};
  idcomentarios = "";
  salesChart: Chart;
  chartColors = {
    red: 'rgba(255, 99, 132,0.5)',
    orange: 'rgba(255, 159, 64,0.5)',
    yellow: 'rgba(255, 205, 86,0.5)',
    green: 'rgba(34,139,34,0.5)',
    blue: 'rgba(54, 162, 235,0.5)',
    purple: 'rgba(153, 102, 255,0.5)',
    grey: 'rgba(201, 203, 207,0.5)'
  };

  constructor(protected route: ActivatedRoute,
    private service: CorrecaoService,
    private authService: AuthService,
    private temaService: TemaService,
    private alunoService: AlunoService,
    private comentarioscorrecaoService: ComentariosCorrecaoService) {
    this.file = null;

  }

  ngOnInit() {
    if (this.authService.logado) {
      this.email = this.authService.email;
      this.idusuario = this.authService.idusuario;
      this.alunoService.getByField('uid', this.idusuario).valueChanges().subscribe(obj => this.aluno = obj);
    }
    this.chave = this.route.params["value"]["id"];
    this.init();
  }

  private init() {
    this.selecionado = {};
    this.selecionado["tema"] = {};
    this.selecionado["correcao"] = {};
    this.file = null;
    this.logo = {};

    if (this.chave !== undefined) {
      this.temaService.get(this.chave).valueChanges().subscribe(obj => {
        this.selecionado["tema"] = obj;

        for (let obj of this.selecionado["tema"]["atividade_aluno"]) {
          if (obj["idusuario"] === this.idusuario) {
            let temp = this.service.get(obj["idcorrecao"]).snapshotChanges().subscribe(item => {
              this.selecionado["correcao"] = item.payload.data();
              this.usuariocorrecao = this.selecionado["correcao"]["idusuario"];
              this.idcorrecao = item.payload.id;
              this.buscaComentarios(this.idcorrecao);
              
              //calcula as notas
              //MODELO ENEM
              if ((this.selecionado["tema"]["tipoTema"] == undefined) || (this.selecionado["tema"]["tipoTema"] == 'ENEM')) {
                if (this.selecionado["correcao"]["nota"] !== undefined) {
                  this.somanotas = parseInt(this.selecionado["correcao"]["nota"]["competencia_1"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_2"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_3"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_4"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_5"]);
                  this.gerarGraficoPie(this.selecionado["correcao"]["nota"]["competencia_1"], this.selecionado["correcao"]["nota"]["competencia_2"], this.selecionado["correcao"]["nota"]["competencia_3"], this.selecionado["correcao"]["nota"]["competencia_4"], this.selecionado["correcao"]["nota"]["competencia_5"], this.somanotas);
                }
              }
              //MODELO UEM
              if (this.selecionado["tema"]["tipoTema"] == 'UEM') {
                if (this.selecionado["correcao"]["nota"] !== undefined) {
                  this.somanotas = parseInt(this.selecionado["correcao"]["nota"]["competencia_1"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_2"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_3"]) + parseInt(this.selecionado["correcao"]["nota"]["competencia_4"]);
                  this.gerarGraficoPieUEM(this.selecionado["correcao"]["nota"]["competencia_1"], this.selecionado["correcao"]["nota"]["competencia_2"], this.selecionado["correcao"]["nota"]["competencia_3"], this.selecionado["correcao"]["nota"]["competencia_4"], this.somanotas);
                }
              }

              temp.unsubscribe();
            })
          }
        }

      })
    }

  }

  buscaComentarios(id) {
    this.comentarios = this.comentarioscorrecaoService.list(id).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        this.idcomentarios = a.payload.doc.id;
        this.listacomentarios = data;
        this.visualizaComentarios();
        return { id, ...data as any };
      })));

  }


  salvarComentario() {

    let c = { quando: new Date().getTime(), usuario: this.idusuario, nome: this.aluno[0]["nome"], comentario: this.comentarionovo, visualizado: false };

    if (this.listacomentarios["comentarios"] == undefined) {
      let coment = { idcorrecao: this.idcorrecao, idusuariocorrecao: this.usuariocorrecao, tema: this.selecionado["tema"]["titulo"], comentarios: [], nomealunoatividade: this.aluno[0]["nome"], idaluno: this.authService.idusuario, idtema: this.chave };
      coment.comentarios.push(c);
      this.comentarioscorrecaoService.add(coment);
    } else {

      this.listacomentarios["comentarios"].push(c);
      this.comentarioscorrecaoService.update(this.idcomentarios, this.listacomentarios);
    }
    this.comentarionovo = "";
  }


  visualizaComentarios() {

    if (this.listacomentarios["comentarios"] != undefined) {
      for (let i = 0; i < this.listacomentarios["comentarios"].length; i++) {
        if (this.idusuario != this.listacomentarios["comentarios"][i]["usuario"]) {
          this.listacomentarios["comentarios"][i]["visualizado"] = true;
        }
      }
      this.comentarioscorrecaoService.update(this.idcomentarios, this.listacomentarios);
    }
  }




  gerarGraficoPie(c1, c2, c3, c4, c5, nota) {

    this.salesChart = new Chart('salesChart', {
      type: 'polarArea',
      data: {
        datasets: [{
          data: [
            c1,
            c2,
            c3,
            c4,
            c5
          ],
          backgroundColor: [
            this.chartColors.red,
            this.chartColors.orange,
            this.chartColors.yellow,
            this.chartColors.green,
            this.chartColors.blue
          ],
          label: 'NOTA ' + nota
        }],
        labels: [
          'Comp I',
          'Comp II',
          'Comp III',
          'Comp IV',
          'Comp V'
        ]
      },
      options: {
        responsive: true
      }
    });

  }




  gerarGraficoPieUEM(c1, c2, c3, c4, nota) {

    this.salesChart = new Chart('salesChart', {
      type: 'polarArea',
      data: {
        datasets: [{
          data: [
            c1,
            c2,
            c3,
            c4         
          ],
          backgroundColor: [
            this.chartColors.red,
            this.chartColors.orange,
            this.chartColors.yellow,
            this.chartColors.green,            
          ],
          label: 'NOTA ' + nota
        }],
        labels: [
          'Tema',
          'Gênero',
          'Coerência',
          'Desemp. Linguístico',
        ]
      },
      options: {
        responsive: true
      }
    });

  }
}
