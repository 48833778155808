import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class ComissaoService {

  private path: string = 'comissao';
  

  constructor(public firebaseService: FirebaseService,
    
    public http: Http) {
  }

  list() {
    return this.firebaseService.query(this.path, ref => ref.where('excluido', '==', false));
  }

  buscaVendedor(vendedor) {
    return this.firebaseService.query(this.path, ref => ref.where('vendedor', '==', vendedor));
  }

  buscaPorStatus(vendedor, pago) {
    return this.firebaseService.query(this.path, ref => ref.where('vendedor', '==', vendedor).where('pago', '==', pago));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }
  
  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }


}
