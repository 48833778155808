import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  private path: string = 'material';

  constructor(public firebaseService: FirebaseService, public authService: AuthService) {
  }

  list() {
    return this.firebaseService.getList(this.path);
  }

  listOrderNome() {
    return this.firebaseService.query(this.path, ref => ref.orderBy('nome', "asc"));
  }

  buscaPorEmailLogado() {
    return this.firebaseService.query(this.path, ref => ref.where('uid', '==', this.authService.idusuario));
  }

  buscaPorEmail(email) {
    return this.firebaseService.query(this.path, ref => ref.where('login', '==', email));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  deleteFieldArquivo(id) {
    this.get(id).ref.update({
      arquivo: firebase.firestore.FieldValue.delete()
    });
  }

}
