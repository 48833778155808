import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from 'angularfire2/storage';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../auth.service';
import { AtividadeService } from '../../services/atividade.service';
import { TemaService } from '../../services/tema.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileService } from '../../services/mobile.service';


@Component({
  templateUrl: 'atividade.component.html'
})
export class AtividadeComponent implements OnInit {

  items: Observable<any[]>;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  email = "";
  closeResult: string;
  arquivoMostrar;
  idusuario = {};
  tipoarquivo = "img";
  mostrarLink = false;

  constructor(private service: AtividadeService, private storage: AngularFireStorage, public authService: AuthService, private modalService: NgbModal, public temaService: TemaService, private sanitizer: DomSanitizer) {
    if (MobileService.verificarSeEhMobile()) {
      this.mostrarLink = true;
    }
  }

  ngOnInit() {
    if (this.authService.logado) {
      this.email = this.authService.email;
      this.idusuario = this.authService.idusuario;
    }

    this.items = this.temaService.listOrderCreateLimit().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as any };
      })));
  }

  //modal code
  open(content, item) {
    let idAtividade = "";
    for (let obj of item["atividade_aluno"]) {
      if (obj["idusuario"] === this.idusuario) {
        idAtividade = obj["idatividade"];
      }
    }

    this.service.get(idAtividade).valueChanges().subscribe(obj => {
      this.tipoarquivo = obj["tipoarquivo"];

      if (this.tipoarquivo === 'pdf') {
        this.arquivoMostrar = this.sanitizer.bypassSecurityTrustResourceUrl(obj["arquivo"]);
      } else {
        this.arquivoMostrar = obj["arquivo"];
      }


      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  verificaPodeEnviarAtividade(item) {
    if(item.desativado){
      return true;
    }

    return this.verificaAtividade(item);
  }



  verificaAtividade(item) {
    if (item["atividade_aluno"] == undefined) {
      return false;
    }

    for (let obj of item["atividade_aluno"]) {
      if (obj["idusuario"] === this.idusuario) {
        return true;
      }
    }
    return false;
  }

  temCorrecao(item) {
    if (item["atividade_aluno"] == undefined) {
      return false;
    }

    for (let obj of item["atividade_aluno"]) {
      if (obj["idusuario"] === this.idusuario && obj["idcorrecao"] !== undefined) {
        return true;
      }
    }
    return false;
  }

  deletarAtividade(item) {
    if (item.arquivo !== undefined && item.arquivo !== null) {
      this.storage.storage.refFromURL(item.arquivo).delete();
    }


    if (item.correcao !== undefined && item.correcao.arquivo !== null) {
      this.storage.storage.refFromURL(item.correcao.arquivo).delete();
    }

    this.service.remove(item.id);
  }

}
