import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class VendedorService {

  private path: string = 'vendedor';
  private urlExcluirVendedor = 'https://us-central1-fabrica-redacao.cloudfunctions.net/excluirUsuario?email=';

  constructor(public firebaseService: FirebaseService,
    
    public http: Http) {
  }

  list() {
    return this.firebaseService.query(this.path, ref => ref.where('excluido', '==', false));
  }

  buscaPorEmailLogado(email) {
    return this.firebaseService.query(this.path, ref => ref.where('login', '==', email));
  }

  buscaPorEmail(email) {
    return this.firebaseService.query(this.path, ref => ref.where('login', '==', email));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  removeAuth(login) {

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*')
    
    
    let temp = this.http.get(this.urlExcluirVendedor + login, { headers: headers }).subscribe(obj => {
      //console.log("aqui");
      temp.unsubscribe();
    });

    
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }


}
