import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CorrecaoService {

  private path: string = 'correcao'; 

  constructor(public firebaseService: FirebaseService, public authService: AuthService) {
  }

  list() {
    return this.firebaseService.getList(this.path);
  }

  listRedacaoByUserId() {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', this.authService.idusuario));
  }

  listCorrecaoByUserId() {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', this.authService.idusuario).orderBy('dataEnvio', "asc"));
  }

  listCorrecaoByUserIdPago() {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', this.authService.idusuario).where('pago', '==', true).orderBy('dataEnvio', "asc"));
  }

  listCorrecaoByUserIdParametroInicioFim(idusuario, inicio, fim) {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', idusuario).where('dataEnvio', '>',inicio).where('dataEnvio', '<',fim).orderBy('dataEnvio', "asc"));
  }

  listCorrecaoByUserIdPagoParametroInicioFim(idusuario, inicio, fim) {
    return this.firebaseService.query(this.path, ref => ref.where('idusuario', '==', idusuario).where('dataEnvio', '>',inicio).where('dataEnvio', '<',fim).where('pago', '==', true).orderBy('dataEnvio', "asc"));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }
  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }
  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }
  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  deleteFieldArquivo(id) {
    this.get(id).ref.update({
      arquivo: firebase.firestore.FieldValue.delete()
    });
  }

  deleteFieldCorrecao(id) {
    this.get(id).ref.update({
      correcao: firebase.firestore.FieldValue.delete()
    });
  }

}
