import { Component, OnInit } from '@angular/core';
import { AtividadeService } from '../../services/atividade.service';
import { ProfessorService } from '../../services/professor.service';
import { AuthService } from '../../auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MaterialService } from '../../services/material.service.';
import { TemaService } from '../../services/tema.service';
import { AlunoService } from '../../services/aluno.service';
import { ComentariosCorrecaoService } from '../../services/comentarios-correcao.service';
import { EmailsService } from '../../services/emails.service';
import { SolicitacaoCompraService } from '../../services/solicitacao-compra.service';
import { PlanoService } from '../../services/plano.service';
import { VendedorService } from '../../services/vendedor.service';
import { ComissaoService } from '../../services/comissao.service';



@Component({
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {


  isReady = false;
  email;

  atividades: Observable<any[]>;
  materiais: Observable<any[]>;
  atividadesAluno: Observable<any[]>;
  temCorrecao = false;
  temasPendentes: Observable<any[]>;
  temasPendentesNovo: Observable<any[]>;
  idusuario = {};

  temTemaPendente = false;

  temAtividadesPendentesParaSelecionarCorretor;
  listaAlunosAguardandoConfirmacao: Observable<any[]>;
  listaAlunosBloqueados: Observable<any[]>;
  listaCorrecoesAguardandoVisualizacao = [];

  listaAlunosLiberados: Observable<any[]>;
  temLiberacao = false;
  temLiberados = false;
  temComentarioNaoLido = false;
  temComentarioNaoLidoAluno = false;
  listaComentarios: Observable<any[]>;
  listaComentariosAluno: Observable<any[]>;
  aluno: any = {};
  cupomenviar = "";
  comprasNaoFinalizadas: Observable<any[]>;
  cupomenviarsolicitacao = "";
  vendedor = {};
  listaParcelasComissao = [];



  constructor(public professorService: ProfessorService,
    public atividadeService: AtividadeService,
    public materialService: MaterialService,
    public temaService: TemaService,
    public authService: AuthService,
    public alunoService: AlunoService,
    private comentarioscorrecaoService: ComentariosCorrecaoService,
    public emailService: EmailsService,
    public solicitacaoCompraService: SolicitacaoCompraService,
    public planoService: PlanoService,
    private vendedorService: VendedorService,
    private comissaoService: ComissaoService) {


  }

  ngOnInit() {

    if (this.authService.logado) {

      //professor
      let temp = this.professorService.buscaPorEmailLogado(this.authService.email.toLowerCase()).valueChanges().subscribe(obj => {
        if (obj[0] !== undefined) {
          this.gerarCorrecoesPendentes();
          this.verificarAtividadePendetesParaEscolherProfessor();
          this.buscaComentariosNaoLidos(this.authService.professor['idusuario']);
          if (obj[0]["admin"]) {
            this.buscaAlunosAguardandoLiberacao();
            this.buscaAlunosLiberados();
            this.buscaAlunosBloqueados();
            this.carregaComprasNaoFinalizadas();
          }
        }
        temp.unsubscribe();
      });

      //aluno
      let sub = this.alunoService.buscaPorUid(this.authService.afAuth.auth.currentUser.uid).valueChanges().subscribe(alu => {
        this.aluno = alu[0];
        //console.log(alu[0], this.authService.email);
        if (this.aluno !== undefined) {

          //this.geraTemasPendentes();
          this.geraTemasPendentesNovo();
          this.gerarMateriaisDeApoio();
          this.gerarAtividadesAlunos();
          this.buscaComentariosNaoLidosAluno(this.authService.idusuario);
        }
        sub.unsubscribe();
      });

      //vendedor
      let vend = this.vendedorService.buscaPorEmail(this.authService.email).valueChanges().subscribe(v => {
        this.vendedor = v[0];
        if (this.vendedor !== undefined) {
          //console.log(this.authService.afAuth.auth.currentUser.uid);
          this.comissaoService.buscaVendedor(this.authService.afAuth.auth.currentUser.uid).valueChanges().subscribe(c => {
            this.listaParcelasComissao = c;
          });
        }
        vend.unsubscribe();
      });

      this.isReady = true;
      this.email = this.authService.email;
      this.idusuario = this.authService.idusuario;

    }
  }



  buscaComentariosNaoLidosAluno(idUsuario) {

    this.listaComentariosAluno = this.comentarioscorrecaoService.getComentariosAlunoNaoLidos(idUsuario).snapshotChanges().pipe(
      map(actions =>
        actions.filter(obj => {

          let achou = false;
          const data = obj.payload.doc.data();
          const id = obj.payload.doc.id;

          achou = false;
          for (let obj of data["comentarios"]) {

            if ((obj["visualizado"] === false) && (obj["usuario"] != this.authService.idusuario)) {
              achou = true;
            }
          }
          return achou;
        }).map(a => {
          this.temComentarioNaoLidoAluno = true;
          const data = a['payload'].doc.data();
          const id = a['payload'].doc.id;
          return { id, ...data as any };
        }))
    );
  }


  buscaComentariosNaoLidos(idprofessor) {

    this.listaComentarios = this.comentarioscorrecaoService.getComentariosProfessorNaoLidos(idprofessor).snapshotChanges().pipe(
      map(actions =>
        actions.filter(obj => {

          let achou = false;
          const data = obj.payload.doc.data();
          const id = obj.payload.doc.id;

          achou = false;
          for (let obj of data["comentarios"]) {
            if ((obj["visualizado"] === false) && (obj["usuario"] != this.authService.idusuario)) {
              achou = true;
            }
          }
          return achou;
        }).map(a => {
          this.temComentarioNaoLido = true;
          const data = a['payload'].doc.data();
          const id = a['payload'].doc.id;
          return { id, ...data as any };
        }))
    );
  }



  contaNaoLidos(comentarios) {
    return comentarios.filter(visto => visto.visualizado === false && visto.usuario !== this.authService.idusuario).length;
  }

  buscaAlunosBloqueados() {
    this.listaAlunosBloqueados = this.alunoService.listBloqueados().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        this.temLiberacao = true;
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as any };
      })));

  }

  buscaAlunosAguardandoLiberacao() {
    this.listaAlunosAguardandoConfirmacao = this.alunoService.listAguardandoConfirmacao().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        this.temLiberacao = true;
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as any };
      })));
  }

  buscaAlunosLiberados() {
    this.listaAlunosLiberados = this.alunoService.listLiberados().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        this.temLiberados = true;
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as any };

      })));
  }


  bloquearAluno(aluno) {
    if (confirm('Deseja realmente bloquear o acesso?')) {
      aluno.liberado = false;
      aluno.UltimobloqueioEm = new Date().getTime();
      this.alunoService.update(aluno.id, aluno);
    }
  }

  liberarAluno(aluno) {
    if (confirm('Deseja realmente liberar o acesso?')) {
      aluno.liberado = true;
      this.authService.criarUsuario(aluno.email, aluno.senha).then(obj => {
        if (obj.code != "auth/email-already-in-use") {
          aluno.uid = obj.user.uid;
          aluno.UltimaLiberacaoEm = new Date().getTime();
          this.alunoService.update(aluno.id, aluno);

        } else {
          alert("Email já em uso, por favor, informe outro email.");
        }

      });
    }

  }

  liberarAlunoBloqueado(aluno) {
    if (confirm('Deseja realmente liberar o acesso?')) {
      aluno.liberado = true;
      aluno.UltimaLiberacaoEm = new Date().getTime();
      this.alunoService.update(aluno.id, aluno);
    }
  }

  gerarCorrecoesPendentes() {
    this.atividades = this.atividadeService.listaAtividadeSemCorrecao()
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {

          this.temCorrecao = true;
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data as any };
        }))
      );
  }


  gerarMateriaisDeApoio() {
    this.materiais = this.materialService.list().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data as any };
      })));
  }



  gerarAtividadesAlunos() {
    this.atividadesAluno = this.atividadeService.listaAtividadeByUserId()
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          this.temCorrecao = true;
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;

          return { id, ...data as any };
        }))
      );
  }

  geraTemasPendentes() {
    this.temasPendentes = this.temaService.listOpenInDate()
      .snapshotChanges().pipe(
        map(actions =>
          actions.filter(obj => {
            const data = obj.payload.doc.data();

            if ((data["inicio"] <= new Date().getTime()) && (data["fim"] >= new Date().getTime())) {
              let achou = false;
              let achouuem = false;
              let bloquearCorrecao = false;




              const id = obj.payload.doc.id;
              if (data["atividade_aluno"] !== undefined) {
                achou = false;
                for (let obj of data["atividade_aluno"]) {
                  if (obj["idusuario"] === this.idusuario) {
                    achou = true;
                  }
                }
              }


              if (data["atividade_aluno_uem"] !== undefined) {
                achouuem = false;
                for (let obj of data["atividade_aluno_uem"]) {
                  if (obj["idusuario"] === this.idusuario) {
                    achouuem = true;
                  }
                }
              }

              if ((this.aluno.plano.quantidadeCorrecoes == undefined) || (this.aluno.plano.quantidadeCorrecoes == 4)) {
                if ((achou == true) || (achouuem == true)) {
                  bloquearCorrecao = true;
                }
              } else if (this.aluno.plano.quantidadeCorrecoes == 8) {
                if (achou == true && achouuem == true) {
                  bloquearCorrecao = true;
                }
              }

              return !bloquearCorrecao;
            } else {
              return false;
            }
          }).map(a => {
            this.temTemaPendente = true;
            const data = a['payload'].doc.data();
            const id = a['payload'].doc.id;
            //if((data["inicio"] >= new Date().getTime())&&(data["fim"] <= new Date().getTime())){
            return { id, ...data as any };
            //}          
          }))
      );
  }

  //1657636482000

  //1657512000000

  geraTemasPendentesNovo() {
    this.temasPendentesNovo = this.temaService.listOpenInDate()
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          this.temTemaPendente=true;
          this.temCorrecao = true;
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          //console.log(data)
          data["liberarCorrecaoUsuario"] = true;
          // if ((data["inicio"] <= new Date().getTime()) && (data["fim"] >= new Date().getTime())) {
          let achou = false;
          let achouuem = false;
          let bloquearCorrecao = false;

          if (data["atividade_aluno"] !== undefined) {
            achou = false;
            for (let obj of data["atividade_aluno"]) {
              if (obj["idusuario"] === this.idusuario) {
                achou = true;

              }
            }
          }

          if (data["atividade_aluno_uem"] !== undefined) {
            achouuem = false;
            for (let obj of data["atividade_aluno_uem"]) {
              if (obj["idusuario"] === this.idusuario) {
                achouuem = true;

              }
            }
          }


          if ((achou == true) || (achouuem == true)) {
            bloquearCorrecao = true;
          }

          data["liberarCorrecaoUsuario"] = !bloquearCorrecao;
          return { id, ...data as any };
          //  }
          //Wy5EzhuxivFn1Q6Tf9Eh
          //aiI9rv8LpvsbH0cn5Bu8        
        }))
      );
  }


  verificarAtividadePendetesParaEscolherProfessor() {
    this.atividadeService.listaAtividadeSemCorretor().snapshotChanges().forEach(obj => {
      this.temAtividadesPendentesParaSelecionarCorretor = obj.length > 0;
    })
  }

  excluirAluno(aluno) {
    if (confirm('Deseja realmente excluir?')) {
      this.alunoService.remove(aluno.id);
    }
  }


  enviarEmailCupom(cupom, aluno) {
    return new Promise(async (res) => {
      await this.emailService.enviaCupomDesconto(cupom, aluno).then(r => res(r));
    }).then(ob => {
      return ob
    });
  }

  async enviarEmail(aluno) {
    if (this.cupomenviar.trim() == "") {
      alert("Preencha o cupom para enviar");
      return;
    }
    aluno["enviocupom"] = new Date().getTime();
    aluno["cupomenviado"] = this.cupomenviar;
    this.alunoService.update(aluno.id, aluno);

    let send = await this.enviarEmailCupom(this.cupomenviar, aluno);
    alert(send);

  }

  enviarServiceSemanaGratis(id, aluno) {
    return new Promise(async (res) => {
      await this.emailService.enviaEmailSemanaGratis(id, aluno).then(r => res(r));
    }).then(ob => {
      return ob
    });
  }

  enviarNotificacaoCompraNaoFinalizada(id, aluno) {
    return new Promise(async (res) => {
      await this.emailService.enviaNotificacaoCompraNaoFinalizada(id, aluno).then(r => res(r));
    }).then(ob => {
      return ob
    });
  }

  async enviarSemanaGratis(aluno) {
    aluno["enviosemanagratis"] = new Date().getTime();
    this.alunoService.update(aluno.id, aluno);
    let send = await this.enviarServiceSemanaGratis(aluno.id, aluno);
    alert(send);
  }

  async getDadosAluno(id) {
    return new Promise(async (res) => {
      await this.alunoService.get(id).valueChanges().subscribe(r => res(r));
    }).then(ob => {
      return ob
    });
  }

  async getDadosPlano(id) {
    return new Promise(async (res) => {
      await this.planoService.get(id).valueChanges().subscribe(r => res(r));
    }).then(ob => {
      return ob
    });
  }

  async carregaComprasNaoFinalizadas() {

    this.comprasNaoFinalizadas = this.solicitacaoCompraService.list().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        data["aluno"] = {};
        data["plano"] = {};
        data["aluno"] = this.getDadosAluno(data["idaluno"]);
        data["plano"] = this.getDadosPlano(data["idplano"]);

        return { id, ...data as any };

      })));
  }

  async enviarEmailSolicitacao(solicitacao) {
    if (this.cupomenviarsolicitacao.trim() == "") {
      alert("Preencha o cupom para enviar");
      return;
    }
    solicitacao["enviocupom"] = new Date().getTime();
    solicitacao["cupomenviado"] = this.cupomenviarsolicitacao;
    this.solicitacaoCompraService.update(solicitacao.id, solicitacao);

    let send = await this.enviarEmailCupom(this.cupomenviar, solicitacao.aluno);
    alert(send);

  }

  async enviarNotificacaoSolicitacao(solicitacao) {
    solicitacao["enviosemanagratis"] = new Date().getTime();
    this.solicitacaoCompraService.update(solicitacao.id, solicitacao);
    let send = await this.enviarNotificacaoCompraNaoFinalizada(solicitacao.id, solicitacao.aluno);
    alert(send);
  }


  async enviarSemanaGratisSolicitacao(solicitacao) {
    solicitacao["enviosemanagratis"] = new Date().getTime();
    this.solicitacaoCompraService.update(solicitacao.id, solicitacao);
    let send = await this.enviarServiceSemanaGratis(solicitacao.id, solicitacao);
    alert(send);
  }

  excluirSolicitacao(solicitacao) {
    if (confirm("Deseja realmente excluir a solicitação de compra?")) {
      this.solicitacaoCompraService.remove(solicitacao.id);
    }
  }


}
