import { FirebaseService } from '../firebase.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AlunoService {

  private path: string = 'aluno';

  constructor(public firebaseService: FirebaseService ) {
  }

  list() {
    return this.firebaseService.query(this.path, ref => ref.where('excluido', '==', false));
  }

  listAlunosPagantes() {
    return this.firebaseService.query(this.path, ref => ref.where('excluido', '==', false).where('vigencia', '>', 0));
  }

  listAguardandoConfirmacao() {
    return this.firebaseService.query(this.path, ref => ref.where('liberado', '==', false).where('uid', '==', ''));
  }

  listLiberados() {
    return this.firebaseService.query(this.path, ref => ref.where('liberado', '==', true));
  }

  listBloqueados(){
    return this.firebaseService.query(this.path, ref => ref.where('liberado', '==', false).where('uid', '>', ''));
  }

  buscaPorUid(uid) {
    return this.firebaseService.query(this.path, ref => ref.where('uid', '==', uid));
  }

  buscaPorEmail(email) {
    return this.firebaseService.query(this.path, ref => ref.where('email', '==', email));
  }

  get(id) {
    return this.firebaseService.getObject(`${this.path}/${id}`);
  }

  add(doc) {
    return this.firebaseService.addItem(this.path, doc);
  }

  update(id, doc) {
    return this.firebaseService.updateItem(`${this.path}/${id}`, doc);
  }

  remove(id) {
    return this.firebaseService.removeItem(this.path, id);
  }

  getByField(campo, valor){
    return this.firebaseService.query(this.path, ref => ref.where(campo, '==', valor));
  }

}
